<template>
  <div :class="['panel__wrapper', { 'is-expaned': expaned }]">
    <div class="panel__header" @click="handleToggleExpaned">
      <span class="panel__title">{{ data.title }}</span>
      <img
        class="panel__arrow-icon"
        src="@/assets/images/arrow-double-up.png"
      />
    </div>
    <div class="panel__body">
      <template v-if="multiple">
        <div
          :class="[
            'sub-panel__wrapper',
            { 'is-expaned': subPanelExpaned[subIndex] }
          ]"
          v-for="(subData, subIndex) in data.list"
          :key="subIndex"
        >
          <div
            class="sub-panel__header"
            @click="handleSubpanelToggleExpaned(subData, subIndex, data)"
          >
            {{ subData.title }}
            <img
              class="sub-panel__arrow-icon"
              src="@/assets/images/arrow-up.png"
            />
          </div>
          <div class="sub-panel__body">
            <el-scrollbar>
              <div class="sub-panel__list">
                <div
                  :class="[
                    'sub-panel__item',
                    { 'is-active': active === item.label }
                  ]"
                  v-for="(item, index) in subData.list"
                  :key="index"
                  @click="handleItemClick(item, index, subData)"
                >
                  <span class="sub-panel__item-name">{{ item.label }}</span>
                  <span class="sub-panel__item-value">{{ item.value }}</span>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </template>
      <template v-else>
        <el-scrollbar>
          <div class="panel__item-list">
            <div
              :class="['panel__item', { 'is-active': active === item.label }]"
              v-for="(item, index) in data.list"
              :key="index"
              @click="handleItemClick(item, index, data)"
            >
              <span class="panel__item-name">{{ item.label }}</span>
              <span class="panel__item-value">{{ item.value }}</span>
            </div>
          </div>
        </el-scrollbar>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: [Object, Array],
    multiple: {
      type: Boolean,
      default: false
    },
    active: {
      type: String
    }
  },
  data() {
    return {
      expaned: true,
      subPanelExpaned: []
    };
  },
  methods: {
    handleToggleExpaned() {
      this.expaned = !this.expaned;
    },
    handleSubpanelToggleExpaned(data, index) {
      this.$set(this.subPanelExpaned, index, !this.subPanelExpaned[index]);
    },
    handleItemClick(item, index, data) {
      console.log(item, index, data);
      this.$emit("item-click", { item, index, data });
    }
  },
  mounted() {
    let activeElm =
      document.querySelector(".sub-panel__item.is-active") ||
      document.querySelector(".panel__item.is-active");
    if (activeElm) {
      activeElm.scrollIntoView();
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.multiple) {
          this.subPanelExpaned = new Array(val.list.length).fill(true);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.panel__wrapper {
  overflow: hidden;
  margin-bottom: 20px;

  &.is-expaned {
    .panel__arrow-icon {
      transform: rotate(-180deg);
    }

    > .panel__body {
      max-height: fit-content;
    }
  }

  .el-scrollbar {
    width: 100%;

    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
      max-height: 180px;
      margin-bottom: 0 !important;
    }
  }

  .panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(88, 131, 248, 0.5);
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 9px 20px;
    cursor: pointer;
    user-select: none;
    position: relative;
    z-index: 1;

    .panel__arrow-icon {
      transition: transform 0.3s ease-in-out;
    }
  }

  .panel__body {
    background: #ffffff;
    transition: max-height 0.3s ease-in-out;
    max-height: 0;

    .panel__item-list {
      .panel__item {
        padding: 0 20px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #333333;
        cursor: pointer;

        &:hover,
        &.is-active {
          color: #5883f8;
        }

        .panel__item-name {
          font-size: 12px;
        }

        .panel__item-value {
          font-size: 14px;
        }
      }
    }

    .sub-panel__wrapper {
      overflow: hidden;
      &.is-expaned {
        .sub-panel__header {
          border-bottom: 1px solid #5883f8;
        }
        .sub-panel__arrow-icon {
          transform: rotate(-180deg);
        }
        > .sub-panel__body {
          max-height: fit-content;
        }
      }

      .sub-panel__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #5883f8;
        font-size: 14px;
        line-height: 20px;
        padding: 8px 20px;
        cursor: pointer;
        user-select: none;
        position: relative;
        z-index: 1;
        .sub-panel__arrow-icon {
          transition: transform 0.3s ease-in-out;
        }
      }

      .sub-panel__body {
        background: #ffffff;
        transition: max-height 0.3s ease-in-out;
        max-height: 0;
        .sub-panel__list {
          .sub-panel__item {
            font-weight: 400;
            color: #333333;
            line-height: 35px;
            height: 35px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &.is-active {
              color: #5883f8;
            }
            .sub-panel__item-name {
              font-size: 12px;
            }

            .sub-panel__item-value {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
