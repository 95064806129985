<template>
  <div class="empty__wrapper">
    <div class="empty__content">
      <img src="@/assets/images/empty.png" />
      <p class="empty__content-title">{{ title }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "暂无数据~"
    }
  }
};
</script>
<style lang="scss" scoped>
.empty__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .empty__content {
    text-align: center;

    .empty__content-title {
      margin-top: 10px;
      font-size: 20px;
      font-family: "ZhanKuKuaiLeTi2016XiuDingBan-2", sans-serif;
      font-weight: normal;
      color: #333333;
      line-height: 23px;
    }
  }
}
</style>
