<template>
  <layout selectedNav="typical-application">
    <div class="page-content">
      <div class="left-side">
        <panel
          :data="category"
          v-for="(category, index) in categories"
          :key="index"
          @item-click="handleNavItemClick"
        >
        </panel>
      </div>
      <div class="right-content" v-loading="showLoading">
        <div class="resource-list" v-if="showList">
          <router-link
            class="resource-item"
            v-for="(resource, index) in resourceList"
            :key="index"
            :to="{
              name: 'TypicalApplicationDetail',
              params: { id: resource.id }
            }"
          >
            <div class="resource-item__cover">
              <img :src="resource.cover" />
            </div>
            <div class="resource-item__right-content">
              <div class="resource-item__header">
                <div class="resource-item__title">{{ resource.title }}</div>
                <span class="resource-item__view">
                  <img src="@/assets/images/viewed.png" />
                  <span>{{ resource.view }}浏览量</span>
                </span>
                <el-tag
                  class="resource-item__category"
                  effect="dark"
                  :style="{ background: resource.color }"
                  type=""
                  >{{ resource.category }}</el-tag
                >
              </div>
              <div class="resource-item__content">
                <div class="resource-item__description">
                  {{ resource.description }}
                </div>
              </div>
              <div class="resource-item__footer">
                <!-- <span>数据来源：{{ resource.from }}</span> -->
                <span class="itemScene">应用场景：{{ resource.scene }}</span>
                <span class="itemUpdate"
                  >更新日期：{{ resource.updateTime }}</span
                >
                <span class="itemPublish"
                  >发布日期：{{ resource.publishTime }}</span
                >
              </div>
            </div>
          </router-link>
        </div>
        <el-pagination
          v-if="showList"
          background
          layout="prev, pager, next"
          :total="form.total"
          :current-page.sync="form.currentPage"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handlePageChange"
        >
        </el-pagination>
        <empty v-if="showEmpty"></empty>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "@/views/Layouts";
import Panel from "@/views/Layouts/panel";
import dayjs from "dayjs";
import Empty from "@/views/Layouts/empty";
export default {
  components: {
    Layout,
    Panel,
    Empty
  },
  data() {
    return {
      categories: [
        {
          title: "成果类型",
          list: [
            {
              label: "政府办公",
              value: 1
            },
            {
              label: "创新方案",
              value: 2
            }
          ]
        }
      ],
      resourceList: [],
      form: {
        type: "",
        total: 0,
        currentPage: 1
      },
      totalCount: 20000,
      showLoading: false,
      showList: false,
      showEmpty: false
    };
  },
  methods: {
    getList() {
      this.showLoading = true;
      this.showList = false;
      this.showEmpty = false;
      this.$api.typicalApplication
        .list({
          leix: this.form.type,
          pagenum: this.form.currentPage
        })
        .then(data => {
          this.showLoading = false;
          console.log(data);
          if (data.code === 200 && data.data.result.length) {
            this.showList = true;
            this.resourceList = data.data.result.map(item => {
              return {
                id: item.ID,
                title: item.title,
                cover: item.yltp,
                description: item.remark,
                scene: item.scene,
                updateTime: item.updatetime
                  ? dayjs(new Date(item.updatetime)).format("YYYY-MM-DD ")
                  : "",
                publishTime: item.createtime
                  ? dayjs(new Date(item.createtime)).format("YYYY-MM-DD ")
                  : "",
                category: item.datarealm,
                view: item.access,
                color: item.color
              };
            });
            this.form.total = data.data.totalRowNum;
          } else {
            this.showEmpty = true;
          }
        })
        .catch(err => {
          console.log(err);
          this.showLoading = false;
          this.showEmpty = true;
        });
    },
    getNav() {
      this.$api.typicalApplication.nav().then(data => {
        console.log(data);
        if (data.code === 200) {
          let types = Object.keys(data.data);
          this.categories = types.map(type => {
            return {
              title: data.data[type].title,
              type: type,
              list: data.data[type].value.map(item => {
                return {
                  label: item.datatitle,
                  value: item.size
                };
              })
            };
          });
        }
      });
    },
    handleNavItemClick({ item }) {
      this.form.type = item.label;
      this.getList();
    },
    handlePageChange() {
      this.getList();
    }
  },
  mounted() {
    this.getNav();
    this.getList();
    if (this.$route.query.type) {
      this.form.type = this.$route.query.type;
    }
    this.getList();
  }
};
</script>
<style lang="scss" scoped>
.page-content {
  width: 1200px;
  display: flex;
  margin: 40px auto;

  .left-side {
    width: 230px;

    .panel-wrapper {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .right-content {
    flex: 1;
    margin-left: 20px;
    min-height: 200px;

    ::v-deep .el-loading-mask {
      background: #f6f6f6;
    }

    .resource-list {
      .resource-item {
        margin-top: 7px;
        background: #ffffff;
        height: 130px;
        width: 100%;
        display: flex;
        box-sizing: border-box;

        .resource-item__cover {
          width: 150px;
          padding: 10px;
          box-sizing: border-box;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .resource-item__right-content {
          flex: 1;
          padding: 10px 20px 15px 0;
        }

        .resource-item__header {
          display: flex;
          align-items: center;
        }

        .resource-item__title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .resource-item__view {
          margin-left: 20px;
          font-size: 12px;
          color: #656565;

          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }
        }

        .resource-item__download {
          margin-left: 20px;
          font-size: 12px;
          color: #656565;

          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }
        }

        .resource-item__category {
          // background: #fff;
          margin-left: 20px;
          border: 0;
          padding: 5px 17px;
          border-radius: 2px;
          height: 30px;
          font-size: 14px;
          line-height: 20px;
        }

        .resource-item__content {
          margin-top: 18px;
          display: flex;
          align-items: center;

          .resource-item__description {
            flex: 1;
            font-size: 14px;
            color: #656565;
            line-height: 20px;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }

        .resource-item__footer {
          position: relative;
          margin-top: 18px;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          font-size: 12px;
          color: #656565;
          line-height: 17px;
          .itemUpdate {
            position: absolute;
            right: 219px;
          }
          .itemPublish {
            position: absolute;
            right: 0;
          }
        }
      }
    }

    .el-pagination {
      margin-top: 6px;
      text-align: right;
      height: 100px;
      width: 100%;
      background: #ffffff;
      padding: 34px 20px;
      box-sizing: border-box;

      ::v-deep .btn-prev,
      ::v-deep .btn-next {
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cccccc;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        height: 32px;
      }

      ::v-deep .el-pager {
        li {
          border-radius: 4px;
          height: 32px;
          width: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &.active {
            background: rgba(88, 131, 248, 0.4);
            border: 1px solid #5883f8;
            color: #3673ff;
          }
        }
      }
    }
  }
}
</style>
